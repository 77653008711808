import React from 'react';
import './not-found.scss';
import PropTypes from 'prop-types';

const NotFound = ({ message, testId }) => {
  return (
    <div className="content-not-found" data-cy={ testId }>
      <h1 id="main">
        { `We're sorry, the page you requested could not be found` }
      </h1>
      <h2 id="sub">
        { message ||
          `The URL may be misspelled or else the page no longer exists` }
      </h2>
    </div>
  );
};

NotFound.propTypes = {
  message: PropTypes.string,
  testId: PropTypes.string
};

export default NotFound;
